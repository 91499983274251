<template>
  <div class="form-floating ">
    <input
      type="text"
      id="idFloatingInput"
      :value="modelValue"
      placeholder=" "
      class="form-control bg-gray-400 border border-b-4 border-gray-700 w-100"
      @input="$emit('update:modelValue', $event.target.value)"
      aria-describedby="inputValidation"
      :required="required"
    />
    <label for="idFloatingInput" class="text-white">{{ title }}</label>
    <span class="text-xs text-gray-300 flex justify-start">{{ subtitle }}</span>
    <div class="invalid-feedback">Por favor, preencha este campo!</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    title: null,
    subtitle: null,
    required: {
      type: [Boolean],
      default: false,
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  background: rgba(128, 128, 128, 0.589) !important;
  color: white !important;
  border-bottom: 2px solid lightgray !important;
}

::placeholder {
  color: white !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 2px solid lightgray;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px rgba(128, 128, 128, 0.589) inset;
  transition: background-color 5000s ease-in-out 0s;
}

</style>