<template>
  <div class="form-floating">
    <textarea
      id="idFloatingTextArea"
      cols="30"
      :value="modelValue"
      style="height: 6em"
      class="form-control"
      placeholder=" "
      @input="$emit('update:modelValue', $event.target.value)"
      :required="required"
    ></textarea>
    <label for="idFloatingTextArea" class="text-white">{{ title }}</label>
    <div class="invalid-feedback">Por favor, preencha este campo!</div>
  </div>
</template>

<script>
export default {
  props: {
    title: null,
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: [Boolean],
      default: false,
    },
  },
};
</script>

<style scoped>
textarea {
  background: rgba(128, 128, 128, 0.589) !important;
  color: white !important;
  border-radius: 0.7em;
  border-bottom: 2px solid lightgray !important;
}

::placeholder {
  color: white !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
</style>