<template>
<div class="row d-flex align-items-center">
    <div class="col-7 text-white"> {{ nomeArquivo }} </div>
    <div class="col-4" style="color: #01E769" > {{ tamanhoArquivo + " KB" }} </div>
    <div class="col-1 text-red"> 
       <button type="button" @click="$emit('clicked', nomeArquivo)">
            <i class="fa-solid fa-trash"></i>
       </button>
    </div>
</div>
</template>

<script>
export default {
    name: 'ArquivosComponent',
    props: {
        nomeArquivo: null,
        tamanhoArquivo: null,
        id: null,
    }
}
</script>

<style>

</style>