<template>
<div class="overlay text-white text-center">
    <div class="mensagem " style="font-size:5vmin;">
      Aguarde o upload completo de sua música...   
    </div>  
  <div class="loader">
  
  </div>
</div>
</template>

<script>
export default {
    name: 'Uploading',

}
</script>

<style scoped>
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  z-index: 1; 
}

.mensagem {
  z-index: 1;
  position: relative;
  top:40%;
  margin: 0 auto;
}

.loader {
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0 0;
  left: 50%;
  top: 50%;
  z-index: 2;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #01E769, 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2), 2.5em 0em 0 0em rgba(1, 231, 105, 0.2), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.2), 0em 2.5em 0 0em rgba(1, 231, 105, 0.2), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.2), -2.6em 0em 0 0em rgba(1, 231, 105, 0.5), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.7), 1.8em -1.8em 0 0em #01E769, 2.5em 0em 0 0em rgba(1, 231, 105, 0.2), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.2), 0em 2.5em 0 0em rgba(1, 231, 105, 0.2), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.2), -2.6em 0em 0 0em rgba(1, 231, 105, 0.2), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.5), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.7), 2.5em 0em 0 0em #01E769, 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.2), 0em 2.5em 0 0em rgba(1, 231, 105, 0.2), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.2), -2.6em 0em 0 0em rgba(1, 231, 105, 0.2), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.2), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.5), 2.5em 0em 0 0em rgba(1, 231, 105, 0.7), 1.75em 1.75em 0 0em #01E769, 0em 2.5em 0 0em rgba(1, 231, 105, 0.2), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.2), -2.6em 0em 0 0em rgba(1, 231, 105, 0.2), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.2), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2), 2.5em 0em 0 0em rgba(1, 231, 105, 0.5), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.7), 0em 2.5em 0 0em #01E769, -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.2), -2.6em 0em 0 0em rgba(1, 231, 105, 0.2), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.2), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2), 2.5em 0em 0 0em rgba(1, 231, 105, 0.2), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.5), 0em 2.5em 0 0em rgba(1, 231, 105, 0.7), -1.8em 1.8em 0 0em #01E769, -2.6em 0em 0 0em rgba(1, 231, 105, 0.2), -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.2), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2), 2.5em 0em 0 0em rgba(1, 231, 105, 0.2), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.2), 0em 2.5em 0 0em rgba(1, 231, 105, 0.5), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.7), -2.6em 0em 0 0em #01E769, -1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(1, 231, 105, 0.2), 1.8em -1.8em 0 0em rgba(1, 231, 105, 0.2), 2.5em 0em 0 0em rgba(1, 231, 105, 0.2), 1.75em 1.75em 0 0em rgba(1, 231, 105, 0.2), 0em 2.5em 0 0em rgba(1, 231, 105, 0.2), -1.8em 1.8em 0 0em rgba(1, 231, 105, 0.5), -2.6em 0em 0 0em rgba(1, 231, 105, 0.7), -1.8em -1.8em 0 0em #01E769;
  }
}


</style>