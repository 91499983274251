<template>
  <div class="banner-1 mt-3" >
    <div class="grid grid-cols-1" >
    <div class="titulo-banner mb-3" >
        <p>A IMPORTÂNCIA DE REGISTRAR SUA MÚSICA/COMPOSIÇÃO:</p>
    </div>

    <div class="texto-banner" >
        As composições musicais tem direitos protegidos pela Lei de Direitos Autorais. 
        A lei garante o uso do registro como prova de anterioridade e também dá 
        total segurança contra plágios, usos indevidos, alterações e utilizações não autorizadas.
    </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'BannerImportanciaRegistro'
}
</script>

<style scoped>
.banner-1 {
  background-image: url('../../../public/assets/img/registrar_musica/banner_2.png');
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: 'Inter';
  width: 100%;
  height: 100%;
  min-height: 15em;
  font-family: 'Inter';
  display: flex;
  align-items: center;
  
}

.titulo-banner p {
    color: #01E769;
}

.texto-banner {
    color: #BFBEBF;
    width: 80%;
    display: block;
}

@media (max-width:700px) {
    .banner-1 {
        box-sizing: border-box;
        /* background-position: right center; */
        background-position: -650px 0;
        background-size: cover;
        width: 100%;
    }

    .titulo-banner p {
        color: #01E769;
        width: 75%;
        font-weight: 500;
    }

    .texto-banner {
        color: #FFF;
        font-size: 15px;
        width: 75%;
    }
}

@media (min-width:700px) and (max-width:1024px) {
    .banner-1 {
        background-position: right center;
    }

    .texto-banner {
        color: #BFBEBF;
        width: 80%;
        display: block;
    }
}
</style>